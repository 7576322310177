.card-container {
    text-align: center;
    width: 914px;
    display: flex;
    flex-direction: column;
}

.card {
    padding: 32px;
    border-radius: 20px;
    border: 1px solid #242424;
    background: linear-gradient(270deg, #FFF 0%, rgba(238, 67, 88, 0.00) 0.01%, rgba(238, 67, 88, 0.20) 100%);
}

.card-text {
    font-size: 16px;
    margin-bottom: 10px;
}

.card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.card-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.card-name {
    font-weight: bold;
    margin-bottom: 5px;
}

.card-title {
    font-size: 14px;
    color: #888;
}

.card-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.card-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 24px;
}

@media (max-width: 998px) {
    .card-container {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 30px !important;
    }

    .mobile-card-review {
        border-radius: 30px !important;
    }

}