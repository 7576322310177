.card1 {
    width: 800px;
    padding: 55px 75px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    border: 1px solid #000;
    background: var(--Soft-Grad, linear-gradient(93deg, #FFF 0%, #FFE4E7 100%));
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15) inset;
}

.card-title1 {
    font-size: 24px;
    font-weight: bold;
}

.card-text1 {
    font-size: 16px;
    line-height: 1.5;
}

.btn {
    font-weight: bold;
    width: 188px;
    background-color: #EE4358 !important;
    color: white !important;
    font-weight: 500;
}

.card-body1 {
    display: flex;
    gap: 60px !important;

}

.btn-modal {
    border-radius: 10px;
    border: 2px solid #EE4358;
    background: #EE4358;
    display: flex;
    padding: 14px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: max-content;
    color: white;
}

@media (max-width: 998px) {
    .card1 {
        width: 342px;
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .card-body1 {
        gap: 24px !important;
        flex-direction: column !important;
        ;
    }

}