body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #fbe7e7;
    color: #333;
}

.app-container {
    /* padding: 20px; */
    background: #FFF5EE;
}

.header {
    text-align: center;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;

}

.header-img {
    width: 100%;
    border-radius: 8px;
}

.services {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.service-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    text-align: center;
}

.service-card h2 {
    margin-bottom: 10px;
    color: #d92626;
}

.service-card p {
    font-size: 1rem;
    margin-bottom: 15px;
}

.service-card button {
    background-color: #d92626;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.service-card button:hover {
    background-color: #b71c1c;
}

.sticky-title {
    position: sticky;
    top: 120px;
    height: fit-content;
    z-index: 1;
    background: white;
}

.service-btn {
    display: flex;
    padding: 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 2px solid #EE4358;
    background: #FFF5EE;
    color: #EE4358;
}

.side-content-service-web {
    padding-left: 150px !important;
}

.header-main-content {
    font-size: 54px;
    font-weight: 800;
}

.header-body-content {
    font-size: 24px;
    padding: 0px 120px;
}

@media (max-width:998px) {
    .service-tile-mobile {
        display: flex !important;
        align-items: center;
        flex-direction: column;
        justify-content: center;

    }

    .service-tile-section-3 {
        padding: 20px !important;

    }

    .sticky-title {
        position: relative;
        top: -30px;

    }

    .side-content-service-web {
        padding-left: 20px !important;
    }

    .header-main-content {
        font-size: 24px;
        font-weight: 800;
    }

    .header-body-content {
        padding: 0px 20px;
        font-size: 16px;

    }

    .header-img {
        display: none;
    }

    .header {
        margin-bottom: 80px;
    }

    .mobile-getting-started-service {
        margin-bottom: 24px !important;
    }

}