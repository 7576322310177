@media (max-width: 998px) {
    .responsive-heading {
        font-size: 32px !important;
        /* Adjust to mobile view */
        margin-bottom: 0px !important;
    }

    .responsive-height-animation {
        height: 40px !important;
    }

    .matrix-wrappper {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .matrix-text {
        font-size: 24px !important;
        text-align: center !important;
    }

    .mobile-matrices {
        display: block !important;
        text-align: center !important;
    }

    .text-mobile-services {
        margin-bottom: 5px !important;
    }


}

.body2 {
    font-family: "Red Hat Display";
    background: #FFF5EE
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff8f5;
}

.text-client {
    color: var(--Text, #262626);
    text-align: center;
    font-family: "Red Hat Display";
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 30px;
}

.service-tile {
    /* background-color: white; */
    border-radius: 30px;
    border: 1px solid white;
    background: var(--Soft-Grad, linear-gradient(93deg, #FFF 0%, #FFE4E7 100%));
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15) inset;
    text-align: left;
}

.matrix-text {
    color: var(--Text, #262626);
    text-align: center;
    font-family: "Red Hat Display";
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.matrix-num {
    background: linear-gradient(93deg, #AF4848 0%, #EE4358 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
}

.service-tile:hover {
    transform: translateY(0px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    background-color: #EE4358 !important;
    overflow: hidden;
}

.content {
    position: relative;
}

.normal-content,
.hover-content {
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.normal-content {
    opacity: 1;
}

.hover-content {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    /* transform: translateY(10px); */
    background-color: #EE4358 !important;
}

.service-tile:hover .normal-content {
    opacity: 0;
    transform: translateY(-10px);
}

.service-tile:hover .hover-content {
    opacity: 1;
    display: block;
    transform: translateY(0px);
}


.btn-white-background:hover {
    color: #EE4358 !important;
}

.btn-red-background:hover {
    color: #EE4358 !important;
    background: white !important;
}