.contact-form {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 600px;
    margin-bottom: 120px;
    border-radius: 20px;
    border: 1px solid #242424;
}

.form-group {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

input,
textarea,
select {
    width: 100%;
    border: none;
    border-bottom: 1px solid #858585;
    outline: none;
    /* Light grey border */
    padding: 12px;
    box-sizing: border-box;
    /* Ensures padding doesn't mess with width */
    font-size: 1rem;
}

input:focus,
textarea:focus,
select:focus {
    border: none !important;
    border-color: white !important;
    border-radius: 0;
    /* Highlight color when focused */
    outline: none !important;
    border-bottom: 1px solid #858585 !important;
    /* Remove default focus outline */
    /* box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); */
    /* Subtle blue glow */
}


textarea {
    resize: none;
}

button[type="submit"] {
    background-color: #ee4358;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
}

button[type="submit"]:hover {
    background-color: #d93048;
}

.contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #FFF5EE;
    padding-top: 100px;
}

h1,
p {
    text-align: center;
    margin-bottom: 20px;
}

.contact-form-mini-container {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid #000;
    background: var(--Soft-Grad, linear-gradient(93deg, #FFF 0%, #FFE4E7 100%));
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15) inset;
}

.contact-us-text {
    color: #EE4358;
    font-family: "Red Hat Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.contact-us-text-2 {
    color: var(--Text, #262626);
    font-family: "Red Hat Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

@media (min-width: 600px) {

    .form-group input,
    .form-group select {
        width: calc(50% - 10px);
        /* To align two inputs side by side */
    }
}

@media (max-width:998px) {
    .container-form-mini-container-mobile {
        flex-direction: column !important;
        padding: 20px !important;
    }

    .contact-us-text {
        font-size: 16px;
    }

    .contact-us-text-2 {
        font-size: 16px;
    }

    .contact-form-mini-container {
        gap: 0px !important;
        padding: 20px !important;
    }
}

/* General dropdown styling */
.select-wrapper {
    position: relative;
    width: 100%;
    /* Ensure responsiveness */
}

select {
    appearance: none;
    /* Remove native styling */
    -webkit-appearance: none;
    /* For Safari */
    -moz-appearance: none;
    /* For Firefox */
    background-color: white;
    /* border: 1px solid #858585; */
    padding: 12px;
    font-size: 1rem;
    color: #242424;
    /* border-radius: 5px; */
    /* Optional: Rounded corners */
    width: 100%;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23858585'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
    text-transform: capitalize;
}

select:focus {
    border-color: #242424;
    outline: none;
}

.select-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23858585'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
}