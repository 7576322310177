.body3 {
    font-family: "Red Hat Display";
    background: #FFF5EE
}

.footer-last-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 998px) {
    .body3 {
        padding-left: 20px !important;
    }
}