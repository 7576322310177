.body1 {
    font-family: "Red Hat Display";
    background: #FFF5EE;
    position: sticky !important;
    top: 0;
    z-index: 2;
}

.btn-header {
    color: #0A0A0A !important;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #FFF5EE !important;
    border: none;
    font-weight: 500;
}

.btn-header:hover {
    color: #EE4358 !important;
}

.btn-header-red {
    color: white !important;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #EE4358;
    border: none;
    font-weight: 500;
}

.navbar-center-align-content {
    margin-left: -84px;
}

@media (max-width: 998px) {
    .body1 {
        padding: 20px !important;
    }

    .navbar-center-align-content {
        margin-left: 0;
    }

    .mobile-border-selection {
        width: 22%;
    }
}

.text-nevItems {
    color: #EE4358 !important;

}

.normal {
    color: #0A0A0A !important
}