.btn-danger {
    background-color: #ee4358;
    border: none;
}

.service-card {
    background-color: #ffffff;
    border-radius: 15px;
    transition: transform 0.2s ease-in-out;
}

.service-card:hover {
    transform: scale(1.05);
}

.card-title {
    color: #ee4358;
    font-size: 1rem;
}

.text-main-about-us {
    color: #262626;
    text-align: center;
    font-family: "Red Hat Display";
    font-size: 54px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.text-section-about-us {
    color: #262626;
    text-align: center;
    font-family: "Red Hat Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    /* 145.833% */
}

.btn-about-us {
    display: flex !important;
    padding: 0px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 2px solid #EE4358 !important;
    background: #FFF5EE !important;
    color: #EE4358 !important;
}

.btn-about-us-2 {
    display: flex !important;
    padding: 10px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    /* border: 2px solid #EE4358 !important; */
    /* background: #FFF5EE !important; */
    /* color: #262626; */
}

.margin-top-apply {
    margin-top: 80px !important;
}

.container-background-about-us {
    background: #FFF5EE;
}

.container-margin-top {
    padding-top: 10rem !important;
    padding-bottom: 6rem !important;
}

.btn-white-background2:hover {
    background-color: #EE4358 !important;
    color: white !important;
}

@media (max-width: 998px) {
    .mobile-view-about-us {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column;
    }

    .mobile-view-container {
        padding: 20px !important;
    }

    .service-wrapper {
        display: none !important;
    }

    .text-main-about-us {
        font-size: 24px;
    }

    .text-section-about-us {
        font-size: 16px;
        line-height: 21px;
    }

    .main-text-wrapper {
        padding: 0px 20px;
    }

}